import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing user-related data.
 *
 * @returns {Object} An object containing state variables and functions related to user data.
 * @property {Array<Object>} offices - The array of returned offices (empty if none returned).
 * @property {number} totalPages - The total number of pages for pagination.
 * @property {Object|null} office - The returned office (null if none returned).
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} searchOffices - A function to search for offices based on criteria.
 * @property {Function} createOffice - A function to create a new office.
 * @property {Function} updateOffice - A function to update an office.
 * @property {Function} getOffice - A function to get a specific office by id.
 */
const useUsers = () => {
    const [user, setUser] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Updates a user.
     *
     * @async
     * @function
     * @param {String} userId
     * @param {Object} updateUserFields - The fields for updating the user, passed from formik. Reference config/form-fields.js or validation/create-user-validations.js for details on properties.
     * @returns {Promise<User>} A promise that resolves when the user update is complete with the user object.
     */
    const updateUser = async (userId, updateUserFields) => {
        try {
            setLoading(true);
            setError(null);

            const updateUserFieldsModified = { ...updateUserFields };

            // Remove any more fields that are not allowed
            delete updateUserFieldsModified.id;
            delete updateUserFieldsModified.email;
            delete updateUserFieldsModified.picture;
            delete updateUserFieldsModified.isActive;

            const path = `/users/${userId}`;
            const response = await callApi(
                path,
                'patch',
                updateUserFieldsModified
            );

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const updatedUser = await response.json();
            setUser(updatedUser);
            return updatedUser;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets a single user and it's info by ID.
     *
     * @async
     * @function
     * @param {string} userId
     * @returns {Promise<User>} A promise that resolves with the returned user.
     */
    const getUser = async (userId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/users/${userId}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedUser = await response.json();
            setUser(returnedUser);
            return returnedUser;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return {
        user,
        loading,
        error,
        updateUser,
        getUser
    };
};

export default useUsers;
