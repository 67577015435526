import React from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import useAuthentication from '../../hooks/useAuthentication.js';

const MainHeader = () => {
    const { logout } = useAuthentication();

    const token = sessionStorage.getItem('accessToken');
    const decoded = jwtDecode(token);

    const authorizedIndividuals = [
        'emi.newgent@eassist.me',
        'justin.brown@eassist.me',
        'ashish.joshi@eassist.me',
        'sarah.mickalson@eassist.me'
    ];

    return (
        <div className="main-header">
            <Link
                to="/"
                style={{
                    marginLeft: '1em',
                    color: 'black',
                    textDecoration: 'none'
                }}>
                <p>Get My Signature</p>
            </Link>
            <Link
                to="updateSignature"
                style={{
                    marginRight: '1em',
                    color: 'black',
                    textDecoration: 'none'
                }}>
                <p>Activate/Update My Signature</p>
            </Link>
            {authorizedIndividuals.includes(decoded.email) && (
                <Link
                    to="/uploadQuarterly"
                    style={{
                        marginRight: '1em',
                        color: 'black',
                        textDecoration: 'none'
                    }}>
                    <p>Upload Image for quarter</p>
                </Link>
            )}
            <div
                onClick={async () => logout()}
                style={{
                    marginRight: '1em',
                    color: 'black',
                    textDecoration: 'none',
                    cursor: 'pointer'
                }}>
                <p>Logout</p>
            </div>
        </div>
    );
};

export default MainHeader;
