import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing user-related data.
 *
 * @returns {Object} An object containing state variables and functions related to user data.
 * @property {Array<Object>} offices - The array of returned offices (empty if none returned).
 * @property {number} totalPages - The total number of pages for pagination.
 * @property {Object|null} office - The returned office (null if none returned).
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} searchOffices - A function to search for offices based on criteria.
 * @property {Function} createOffice - A function to create a new office.
 * @property {Function} updateOffice - A function to update an office.
 * @property {Function} getOffice - A function to get a specific office by id.
 */
const usePhotos = () => {
    const [image, setImage] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Uploads a new quarterly image.
     *
     * @async
     * @function
     * @param {String} name
     * @param {Object} file
     * @returns {Promise<Image>} A promise that resolves with the new image object.
     */
    const uploadImage = async (name, file) => {
        try {
            setLoading(true);
            setError(null);

            const fileData = new FormData();
            fileData.append('file', file);
            fileData.append('name', name);

            const path = `/photos/uploadQuarterly`;
            const response = await callApi(path, 'file', fileData);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const newImage = await response.json();
            setImage(newImage);
            return newImage;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets the current quarters image info.
     *
     * @async
     * @function
     * @returns {Promise<Image>} A promise that resolves with the returned image.
     */
    const getCurrentQuarterImage = async () => {
        try {
            setLoading(true);
            setError(null);

            const path = `/photos/currentQuarter`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedImage = await response.json();
            setImage(returnedImage);
            return returnedImage;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return {
        image,
        loading,
        error,
        uploadImage,
        getCurrentQuarterImage
    };
};

export default usePhotos;
