import { useState } from 'react';
// eslint-disable-next-line import/extensions
import { FaWindowClose } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import updateUserValidation from '../../validation/update-user-validation.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useUsers from '../../hooks/useUsers.js';

const UpdateSignatureForm = ({ objectToUpdate }) => {
    const { error, loading, updateUser, user } = useUsers();

    const formik = useFormik({
        initialValues: objectToUpdate,
        validationSchema: updateUserValidation,
        onSubmit: async (values) => {
            // Convert ext from string to number
            const modifiedValues = { ...values };
            if (modifiedValues.ext) {
                modifiedValues.ext = parseInt(modifiedValues.ext, 10);
            } else {
                modifiedValues.ext = null;
            }
            await updateUser(objectToUpdate.id, modifiedValues);
        }
    });

    const formatPhoneNumber = (value) => {
        if (!value) return value;

        // Remove all non-numeric characters except "+"
        const phoneNumber = value.replace(/[^\d+]/g, '');

        // Format U.S.-specific numbers with +1 country code
        if (phoneNumber.startsWith('+1')) {
            const areaCode = phoneNumber.slice(2, 5);
            const prefix = phoneNumber.slice(5, 8);
            const lineNumber = phoneNumber.slice(8, 12);
            if (phoneNumber.length <= 5) return `+1 (${areaCode}`;
            if (phoneNumber.length <= 8) return `+1 (${areaCode}) ${prefix}`;
            return `+1 (${areaCode}) ${prefix}-${lineNumber}`;
        }

        // Format without country code as (XXX) XXX-XXXX
        if (!phoneNumber.startsWith('+')) {
            const areaCode = phoneNumber.slice(0, 3);
            const prefix = phoneNumber.slice(3, 6);
            const lineNumber = phoneNumber.slice(6, 10);
            if (phoneNumber.length <= 3) return `(${areaCode}`;
            if (phoneNumber.length <= 6) return `(${areaCode}) ${prefix}`;
            return `(${areaCode}) ${prefix}-${lineNumber}`;
        }

        // Return other international formats as-is
        return phoneNumber;
    };

    const formatExtNumber = (e) => {
        const value = e.target.value.replace(/[^\d]/g, ''); // Only digits
        if (value.length <= 5) {
            // Max 5 digits
            formik.setFieldValue('ext', value);
        }
    };

    const [newPhrase, setNewPhrase] = useState('');

    const handleAddPhrase = (e) => {
        if (e.key === 'Enter' && newPhrase !== '') {
            formik.setFieldValue('other', [...formik.values.other, newPhrase]);
            setNewPhrase('');
            e.preventDefault();
        }
    };
    const handleRemovePhrase = (toRemove) => {
        formik.setFieldValue(
            'other',
            formik.values.other.filter((phrase) => phrase !== toRemove)
        );
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '5em'
                    }}>
                    <h4>
                        {objectToUpdate.isActive
                            ? 'Update your signature'
                            : 'Activate your signature'}
                    </h4>
                    <p>
                        (Enter info exactly as you want it to appear on your
                        signature)
                    </p>

                    <label htmlFor="name">First and Last Name</label>
                    <input
                        id="name"
                        placeholder="First and Last Name"
                        className="create-input"
                        {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <ErrorMessage message={formik.errors.name} />
                    )}

                    <label htmlFor="title">
                        Position in eAssist (ex: Success Consultant)
                    </label>
                    <input
                        id="title"
                        placeholder="Title"
                        className="create-input"
                        {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                        <ErrorMessage message={formik.errors.title} />
                    )}

                    <label htmlFor="phone">Phone number</label>
                    <input
                        id="phone"
                        placeholder="e.g. (801) 123-1234 or +1 (801)-123-1234"
                        className="create-input"
                        {...formik.getFieldProps('phone')}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'phone',
                                formatPhoneNumber(e.target.value)
                            );
                        }}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                        <ErrorMessage message={formik.errors.phone} />
                    )}

                    <label htmlFor="ext">Extension</label>
                    <input
                        id="ext"
                        placeholder="Extension (1 to 5 digits)"
                        className="create-input"
                        {...formik.getFieldProps('ext')}
                        onChange={formatExtNumber}
                    />
                    {formik.touched.ext && formik.errors.ext && (
                        <ErrorMessage message={formik.errors.ext} />
                    )}

                    <label htmlFor="other">Calendly</label>
                    <input
                        id="other"
                        placeholder="Type a phrase and press enter to add it to your signature"
                        className="create-input"
                        onChange={(e) => setNewPhrase(e.target.value)}
                        onKeyDown={handleAddPhrase}
                        value={newPhrase}
                    />
                    <div style={{ width: '50%' }}>
                        <p>
                            Your phrases:{' '}
                            {formik.values.other.length === 0
                                ? 'no phrases added'
                                : null}
                        </p>
                        {formik.values.other.map((phrase) => (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                                key={uuidv4()}>
                                <p>{phrase}</p>
                                <FaWindowClose
                                    onClick={() => handleRemovePhrase(phrase)}
                                    style={{ cursor: 'pointer', color: 'red' }}
                                />
                            </div>
                        ))}
                    </div>
                    <button
                        className="btn btn-primary btn-block"
                        style={{ marginBottom: '3em' }}
                        type="submit">
                        {objectToUpdate.isActive
                            ? 'Make Changes'
                            : 'Activate Signature'}
                    </button>
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 && (
                <ErrorMessage message="There are form errors above, please fix." />
            )}
            {error && <ErrorMessage message={error.message} />}
            {user && objectToUpdate.isActive && (
                <SuccessMessage message="Signature has been updated!" />
            )}
            {user && !objectToUpdate.isActive && (
                <SuccessMessage message="Signature has been activated!" />
            )}
        </>
    );
};

UpdateSignatureForm.propTypes = {
    objectToUpdate: PropTypes.object.isRequired
};

export default UpdateSignatureForm;
