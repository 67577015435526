import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import Signature from '../components/get-signature/Signature.jsx';
import useUsers from '../hooks/useUsers.js';
import LoadingSpinner from '../components/common/LoadingSpinner.jsx';
import ErrorMessage from '../components/common/ErrorMessage.jsx';

const GetSignaturePage = () => {
    const { error, loading, getUser, user } = useUsers();

    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const token = sessionStorage.getItem('accessToken');
            const decoded = jwtDecode(token);

            await getUser(decoded.sub);
        };

        fetchData();
    }, [refresh]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '5em'
            }}>
            <h4 style={{ marginBottom: '1em' }}>
                Press the button to refresh this quarter&apos;s signature for{' '}
                {user ? user.email : ''}
            </h4>
            <p>
                Need help? &nbsp;
                <a
                    href="https://docs.google.com/document/d/1JJNxzf17bflh-zsxfxvcoxR47xmRp2omyHB2W1xmJoQ"
                    target="_blank"
                    rel="noreferrer">
                    Click here
                </a>
                &nbsp; for a guide on using the signature generator
            </p>
            <button
                className="btn btn-primary btn-block"
                onClick={() => setRefresh(refresh + 1)}>
                Refresh Signature
            </button>
            <br />

            {user && user.isActive && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '3em',
                            width: '80%'
                        }}>
                        <p>
                            {
                                "To set your signature go to your eassist gmail and click the settings gear, then click 'See all settings'. Scroll down to where it says signature, then copy and paste the signature below in place of your current signature."
                            }
                        </p>
                    </div>
                    <Signature
                        name={user.name}
                        title={user.title}
                        email={user.email}
                        phone={user.phone}
                        ext={user.ext}
                        other={user.other}
                        picture={user.picture}
                    />
                </>
            )}
            {loading && <LoadingSpinner />}
            {user && !user.isActive && (
                <ErrorMessage message="You're signature is not yet active. Go to activate/update signature to activate it." />
            )}
            {error && <ErrorMessage message={error.message} />}
        </div>
    );
};

export default GetSignaturePage;
