import httpStatus from 'http-status';
import signaturesApiUrl from '../config/signatures-api-url.js';

/**
 * Refreshes the access token using the refresh token stored in the session storage.
 * If the refresh token is expired or an error occurs, logs the user out.
 *
 * @async
 * @function
 * @returns {Promise<void>} A promise that resolves when the token is refreshed successfully.
 */
const refreshTokens = async () => {
    const refreshToken = sessionStorage.getItem('refreshToken');

    const response = await fetch(`${signaturesApiUrl}/auth/refresh-tokens`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            refreshToken
        })
    });

    if (response.ok) {
        const { access } = await response.json();
        sessionStorage.setItem('accessToken', access.token);
    }
    // Take back to login, refresh token is expired or error occured
    else {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        window.location.href = '/';
    }
};

/**
 * Calls the Signatures API with the specified path, method, headers, and body.
 * Handles token refresh if the access token is expired.
 *
 * @async
 * @function
 * @param {string} path - The API endpoint path.
 * @param {string} method - The HTTP method (get, post, patch, delete).
 * @param {Object} body - The request body (for POST, PATCH, DELETE requests).
 * @param {Object} additionalHeaders - Additional headers to include in the request.
 * @throws {Error} Throws an error if an unexpected condition occurs.
 * @returns {Promise<Response>} A promise containing the API response.
 */
const callApi = async (path, method, body = {}, additionalHeaders = {}) => {
    // eslint-disable-next-line no-param-reassign
    method = method.toLowerCase();

    const url = `${signaturesApiUrl}${path}`;
    let response;

    switch (method) {
        case 'get': {
            response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionStorage.getItem(
                        'accessToken'
                    )}`,
                    ...additionalHeaders
                }
            });

            if (
                response.status === httpStatus.UNAUTHORIZED &&
                sessionStorage.getItem('refreshToken')
            ) {
                // Refresh tokens
                await refreshTokens();

                response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${sessionStorage.getItem(
                            'accessToken'
                        )}`,
                        ...additionalHeaders
                    }
                });
            }
            break;
        }
        case 'post': {
            if (!body) {
                throw new Error('Body must be passed');
            }

            response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionStorage.getItem(
                        'accessToken'
                    )}`,
                    ...additionalHeaders
                },
                body: JSON.stringify({ ...body })
            });

            if (
                response.status === httpStatus.UNAUTHORIZED &&
                sessionStorage.getItem('refreshToken')
            ) {
                // Refresh tokens
                await refreshTokens();

                response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${sessionStorage.getItem(
                            'accessToken'
                        )}`,
                        ...additionalHeaders
                    },
                    body: JSON.stringify({ ...body })
                });
            }
            break;
        }
        case 'patch': {
            if (!body) {
                throw new Error('Body must be passed');
            }

            response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionStorage.getItem(
                        'accessToken'
                    )}`,
                    ...additionalHeaders
                },
                body: JSON.stringify({ ...body })
            });

            if (
                response.status === httpStatus.UNAUTHORIZED &&
                sessionStorage.getItem('refreshToken')
            ) {
                // Refresh tokens
                await refreshTokens();

                response = await fetch(url, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${sessionStorage.getItem(
                            'accessToken'
                        )}`,
                        ...additionalHeaders
                    },
                    body: JSON.stringify({ ...body })
                });
            }
            break;
        }
        case 'delete': {
            response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionStorage.getItem(
                        'accessToken'
                    )}`,
                    ...additionalHeaders
                }
            });

            if (
                response.status === httpStatus.UNAUTHORIZED &&
                sessionStorage.getItem('refreshToken')
            ) {
                // Refresh tokens
                await refreshTokens();

                response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${sessionStorage.getItem(
                            'accessToken'
                        )}`,
                        ...additionalHeaders
                    }
                });
            }
            break;
        }
        case 'file': {
            if (!body) {
                throw new Error('Body must be passed');
            }

            response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                        'accessToken'
                    )}`,
                    ...additionalHeaders
                },
                body
            });

            if (
                response.status === httpStatus.UNAUTHORIZED &&
                sessionStorage.getItem('refreshToken')
            ) {
                // Refresh tokens
                await refreshTokens();

                response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem(
                            'accessToken'
                        )}`,
                        ...additionalHeaders
                    },
                    body
                });
            }
            break;
        }
        default: {
            throw new Error('Unknown method passed');
        }
    }

    return response;
};

export default callApi;
