/**
 * Determines the Signatures API URL based on the current environment.
 *
 * @type {string}
 * @throws {Error} Throws an error if an unrecognized React App environment is detected.
 * @example
 * // Usage
 * import oracleApiUrl from './path/to/your/file';
 *
 * console.log(signaturesApiUrl); // Outputs the determined Signatures API URL
 */
const s3BucketUrl = (() => {
    switch (process.env.REACT_APP_ENV) {
        case 'development': {
            return 'https://signatures-testing-bucket.s3.us-east-2.amazonaws.com'; // Development URL
        }
        case 'testing': {
            return 'https://signatures-testing-bucket.s3.us-east-2.amazonaws.com'; // Testing URL
        }
        case 'production': {
            return 'https://signatures-production-bucket.s3.us-east-2.amazonaws.com'; // Production URL
        }
        default: {
            throw new Error('Unrecognized React App Environment');
        }
    }
})();
export default s3BucketUrl;
