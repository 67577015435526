import * as Yup from 'yup';

/**
 * Yup validation schema for updating a user.
 */
const updateUserValidation = Yup.object({
    name: Yup.string().trim().required('Required'),
    title: Yup.string().trim().required('Required'),
    phone: Yup.string().trim().required('Required'),
    ext: Yup.number().positive().integer().min(1).max(99999).nullable(),
    other: Yup.array()
});

export default updateUserValidation;
