/**
 * Determines the Signatures API URL based on the current environment.
 *
 * @type {string}
 * @throws {Error} Throws an error if an unrecognized React App environment is detected.
 * @example
 * // Usage
 * import oracleApiUrl from './path/to/your/file';
 *
 * console.log(signaturesApiUrl); // Outputs the determined Signatures API URL
 */
const signaturesApiUrl = (() => {
    switch (process.env.REACT_APP_ENV) {
        case 'development': {
            return 'http://localhost:8080'; // Development API URL
        }
        case 'testing': {
            return 'http://localhost:8080'; // Testing API URL
        }
        case 'production': {
            return 'https://signature-api.eassist.me'; // Production API URL
        }
        default: {
            throw new Error('Unrecognized React App Environment');
        }
    }
})();
export default signaturesApiUrl;
