import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './styles/App.css';
import LoginPage from './pages/LoginPage.jsx';
import MainHeader from './components/header/MainHeader.jsx';
import GetSignaturePage from './pages/GetSignaturePage.jsx';
import UpdateSignaturePage from './pages/UpdateSignaturePage.jsx';
import UploadPage from './pages/UploadPage.jsx';

function App() {
    const accesstoken = sessionStorage.getItem('accessToken');
    const refreshToken = sessionStorage.getItem('refreshToken');

    // If not logged in, load login screen
    if (!accesstoken || !refreshToken) {
        return (
            <Router>
                <Routes>
                    <Route path="/*" element={<LoginPage />} />
                </Routes>
            </Router>
        );
    }

    return (
        <div className="app">
            <Router>
                <MainHeader />
                <Routes>
                    {/* Get Signature Page */}
                    <Route path="/*" element={<GetSignaturePage />} />

                    {/* Update Signature Page */}
                    <Route
                        path="/updateSignature"
                        element={<UpdateSignaturePage />}
                    />

                    {/* Upload Page */}
                    <Route path="/uploadQuarterly" element={<UploadPage />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
