import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import usePhotos from '../../hooks/usePhotos.js';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';
import s3BucketUrl from '../../config/s3-bucket-url.js';

const Signature = ({ name, title, email, phone, ext, other, picture }) => {
    const { error, loading, getCurrentQuarterImage, image } = usePhotos();

    useEffect(() => {
        const fetchData = async () => {
            await getCurrentQuarterImage();
        };

        fetchData();
    }, []);

    return (
        <>
            <div
                style={{
                    marginTop: '10em',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '85%',
                    marginBottom: '5em'
                }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        width: '25em'
                    }}>
                    <div style={{ marginRight: '2em' }}>
                        <img
                            src={picture}
                            alt="profile pic"
                            style={{
                                height: '112px',
                                width: '112px',
                                borderRadius: '50%'
                            }}
                        />
                    </div>
                    <div>
                        <p
                            style={{
                                fontSize: '.85em',
                                marginBottom: '0em',
                                marginTop: '0em'
                            }}>
                            <b>{name}</b>
                        </p>
                        <p
                            style={{
                                fontSize: '.85em',
                                marginBottom: '0em',
                                marginTop: '0em'
                            }}>
                            <em>{title}</em>
                        </p>
                        <p
                            style={{
                                fontSize: '.85em',
                                marginBottom: '0em',
                                marginTop: '0em'
                            }}>
                            eAssist Dental Solutions
                        </p>
                        <p
                            style={{
                                fontSize: '.85em',
                                marginBottom: '0em',
                                marginTop: '0em'
                            }}>
                            {email}
                        </p>
                        {ext ? (
                            <p
                                style={{
                                    fontSize: '.85em',
                                    marginBottom: '0em',
                                    marginTop: '0em'
                                }}>
                                {phone} Ext. {ext}
                            </p>
                        ) : (
                            <p
                                style={{
                                    fontSize: '.85em',
                                    marginBottom: '0em',
                                    marginTop: '0em'
                                }}>
                                {phone}
                            </p>
                        )}

                        {other.map((o) => (
                            <p
                                style={{
                                    fontSize: '.85em',
                                    marginBottom: '0em',
                                    marginTop: '0em'
                                }}
                                key={uuidv4()}>
                                {o}
                            </p>
                        ))}
                        <div style={{ height: '.8em' }} />
                        <div>
                            <a
                                href="https://www.facebook.com/eAssistMe/"
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    src={`${s3BucketUrl}/facebook20x20.jpg`}
                                    alt="facebook"
                                    style={{ marginRight: '.5px' }}
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/eassist"
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    src={`${s3BucketUrl}/linkedin20x20.jpg`}
                                    alt="linkedin"
                                    style={{ marginRight: '.5px' }}
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/eassist.me/"
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    src={`${s3BucketUrl}/instagram20x20.jpg`}
                                    alt="instagram"
                                    style={{ marginRight: '.5px' }}
                                />
                            </a>
                            <a
                                href="https://twitter.com/eassistme"
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    src={`${s3BucketUrl}/twitter20x20.jpg`}
                                    alt="twiiter"
                                    style={{ marginRight: '.5px' }}
                                />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCKYpQs29a-N6MavL4P9fr4A?reload=9"
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    src={`${s3BucketUrl}/youtube20x20.jpg`}
                                    alt="youtube"
                                    style={{ marginRight: '.5px' }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{ height: '12px' }} />
                {image && (
                    <img
                        src={image.imageUrl}
                        alt="eassist logo"
                        style={{ width: '400px' }}
                    />
                )}
                {loading && <LoadingSpinner />}
                <p style={{ fontSize: '12px', width: '800px' }}>
                    <em>
                        This email, including any attachments, is confidential,
                        may be legally privileged, and is intended solely for
                        the named recipient(s). If you received this email in
                        error, please notify the sender immediately, destroy any
                        hard copy, and permanently delete this email and any
                        attachments from your system. If you are not the
                        intended recipient, your access to this email is
                        unauthorized, and any disclosure, copying, distribution,
                        or use of the contents of this email, including any
                        attachments, for any reason is strictly prohibited.
                        Emails may contain viruses, worms or other malicious
                        code, and the recipient accepts full responsibility for
                        taking protective action against all of the foregoing.
                        eAssist is not liable for any damage or loss arising
                        from this email, including any attachments. If you have
                        received this email in error, or would like to opt out
                        of future marketing emails, please reply immediately to
                        the sender. Thank you. 665 West 750 North, American
                        Fork, UT 84003
                    </em>
                </p>
            </div>
            {error && <ErrorMessage message={error.message} />}
        </>
    );
};

Signature.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    ext: PropTypes.oneOfType([PropTypes.number, PropTypes.null]).isRequired,
    other: PropTypes.array.isRequired,
    picture: PropTypes.string.isRequired
};

export default Signature;
