import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import useUsers from '../hooks/useUsers.js';
import ErrorMessage from '../components/common/ErrorMessage.jsx';
import LoadingSpinner from '../components/common/LoadingSpinner.jsx';
import UpdateSignatureForm from '../components/update-signature/UpdateSignatureForm.jsx';

const UpdateSignaturePage = () => {
    const { error, loading, getUser, user } = useUsers();

    useEffect(() => {
        const fetchData = async () => {
            const token = sessionStorage.getItem('accessToken');
            const decoded = jwtDecode(token);

            await getUser(decoded.sub);
        };

        fetchData();
    }, []);

    return (
        <div>
            {loading && <LoadingSpinner />}
            {error && <ErrorMessage message={error.message} />}
            {!loading && !error && user && (
                <UpdateSignatureForm objectToUpdate={user} />
            )}
        </div>
    );
};

export default UpdateSignaturePage;
