import { useState } from 'react';
import usePhotos from '../hooks/usePhotos.js';
import LoadingSpinner from '../components/common/LoadingSpinner.jsx';
import ErrorMessage from '../components/common/ErrorMessage.jsx';
import SuccessMessage from '../components/common/SuccessMessage.jsx';

const UploadPage = () => {
    const { loading, error, uploadImage, image } = usePhotos();

    const [name, setName] = useState('');
    const [file, setFile] = useState(null);

    const handleFileSelection = (e) => {
        setFile(e.target.files[0]);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <h4 style={{ marginBottom: '2em' }}>
                Upload New Quarterly Picture
            </h4>
            <p>Enter the name of the quarter that this signature is for</p>
            <input
                placeholder="Quarter"
                className="create-input"
                onChange={(e) => setName(e.target.value)}
            />
            <div className="input-group mb-3" style={{ width: '50%' }}>
                <input
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                    onChange={handleFileSelection}
                    accept="image/png, image/gif, image/jpeg"
                />
            </div>
            <p>Must be jpeg, jpg, or png</p>
            <button
                className="btn btn-primary btn-block"
                onClick={() => uploadImage(name, file)}>
                Update picture
            </button>
            <br />
            {loading && <LoadingSpinner />}
            {error && <ErrorMessage message={error.message} />}
            {image && (
                <SuccessMessage message="Picture successfully updated!" />
            )}
        </div>
    );
};

export default UploadPage;
